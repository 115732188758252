import cx from 'classnames';
import Typography from '@common_typography/index';

const PricePercentage = (props) => {
    const {
        priceRange,
    } = props;

    const regularPrice = priceRange?.minimum_price?.regular_price || 0;
    const finalPrice = priceRange?.minimum_price?.final_price || 0;
    const discountPercentage = regularPrice !== 0 ? Math.round(((regularPrice.value - finalPrice.value) / regularPrice.value) * 100) : 0;

    return (
        <>
            <Typography className={cx(
                'discount-percentage',
                '!text-neutral-white bg-red-450 font-semibold px-[5px] leading-none rounded !text-[13px]',
            )}
            >
                -
                {discountPercentage}
                %
            </Typography>
        </>
    );
};

export default PricePercentage;
